import React, { useState, useEffect } from 'react';
import HeroImage from '../components/HeroImage';
import '../styles/Gallery.css';

import { getRestClient } from '../services';
import { readItems } from '@directus/sdk';

const fetchImages = async () => {
  try {
    const client = getRestClient();
    const response = await client.request(
      readItems('gallery', {
        fields: [
          {
            images: ['*'],
          },
        ],
      }),
    );

    return response?.images || [];
  } catch (error) {
    console.log('📢[Faculty.jsx:62]: error: ', error);
    return [];
  }
};

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const school_infrastructures = [
    { type: 'Laboratory', image: 'images/scholarship.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h1.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h2.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h3.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h4.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h5.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h6.jpeg', name: '' },
  ];
  const [infras, setInfras] = useState(school_infrastructures);
  const getFilterdData = (updatedInfras) => {
    setInfras(updatedInfras);
  };

  useEffect(() => {
    setLoading(true);
    fetchImages().then((res) => {
      setGalleryImages(res);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <HeroImage />
      {console.log(galleryImages)}
      <div
        style={{
          textAlign: 'center',
          // backgroundColor: "#ecf5ff",
          paddingTop: '4.5rem',
          paddingBottom: '4.5rem',
          marginBottom: '30px',
          color: '#0e2245',
        }}>
        <h1>Gallery</h1>
      </div>
      <div className='container'>
        {loading && (
          <div className='tw-w-full'>
            <div className='tw-py-8 tw-text-center'>Loading...</div>
          </div>
        )}
        <div className='row'>
          {/* <InfraFilter getFilterdData={getFilterdData} school_infrastructures={school_infrastructures}/> */}
          {/* I have commented out the school infra filter */}
        </div>
        <div className='gallery_container'>
          <div className='row'>
            {galleryImages.map((galleryImage) => (
              <div className='col-lg-3 col-md-6 col-12'>
                <div className='Gallery_Image_container'>
                  <img
                    src={`https://cns-admin.technohubnetwork.com/assets/${galleryImage.directus_files_id}`}
                    alt='schoolImg'
                    width='250px'
                    height='250px'
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Gallery;
