import React, { useEffect, useState } from 'react';
import HeroImage from '../../components/HeroImage';
import ArtGallery from '../../components/ArtGallery';
import TableComponent from '../../components/TableComponent';
import Heading from '../../components/Heading';
import TitleComponent from '../../components/TitleComponent';
import '../../styles/Administration/Faculty.css';
import { getRestClient } from '../../services';
import { readItems } from '@directus/sdk';

const TABLE_HEADINGS = ['SL.No.', 'Name', 'Designation', 'Qualification'];

const Faculty = () => {
  const [loading, setLoading] = useState(false);
  const [teachingStaffs, setTeachingStaffs] = useState([]);
  const [nonTeachingStaffs, setNonTeachingStaffs] = useState([]);

  const description = '';

  const fetchFaculties = async () => {
    try {
      const client = getRestClient();
      const response = await client.request(
        readItems('facultie', {
          fields: ['id', 'name', 'designation', 'qualification', 'status', 'type'],
          filter: {
            status: 'published',
          },
        }),
      );

      setTeachingStaffs(() =>
        response
          .filter((item) => item.type === 'Teaching')
          .map((item, index) => [index + 1, item.name, item.designation, item.qualification]),
      );
      setNonTeachingStaffs(() =>
        response
          .filter((item) => item.type === 'Non Teaching')
          .map((item, index) => [index + 1, item.name, item.designation, item.qualification]),
      );
    } catch (error) {
      console.log('📢[Faculty.jsx:62]: error: ', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchFaculties().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <HeroImage />
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <Heading
              title='Our Faculties'
              description={description}
              colHeading={'col-lg-4'}
              colDescription={'col-lg-8'}
            />
            <TitleComponent data={'Our Teaching Faculties'} />
            <div className='tableScroll'>
              <TableComponent
                headings={TABLE_HEADINGS}
                rows={teachingStaffs}
                className='tableShadow'
                loading={loading}
              />
            </div>

            <TitleComponent data={'Our Non-Teaching Faculties'} />
            <div className='tableScroll'>
              <TableComponent headings={TABLE_HEADINGS} rows={nonTeachingStaffs} loading={loading} />
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Faculty;
